.c-sidebar-brand{
    background: #ebedef !important; 
}

.c-login-card{
    background: $h-blue !important; 
}

table {
    font-size: 0.8rem;
    margin: 0;
    border: none;
}

table td,
table th {
    padding: 0.5rem 0.5rem;
}

table thead th {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
    // width: 14vw;
    background: white !important;
}

table td {
    // text-align: center;
    vertical-align: middle;
    // width: 14vw;
}

table tbody th {
    position: relative;
}

table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

table tbody tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

table tbody th {
    text-align: center;
    position: sticky;
    left: 0;
    background: white !important;
    z-index: 1;
}

[role="region"][aria-labelledby][tabindex] {
    // width: 100%;
    max-height: 98vh;
    overflow: auto;
}
[role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
}

@media screen and (max-width:767px) {
    .fc-toolbar.fc-header-toolbar {
        font-size: 60%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media screen and (max-width:767px) {
    .fc-col-header-cell-cushion {
        font-size: 80%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.texto-eventos{
    font-family: 'Urbanist';
    font-size: 0.8rem;
    text-align: left;
    color: #ffffff;
}

@media screen and (max-width : 767px) {
    .texto-eventos {
        font-size: 80%; display:table-caption; 
    }
}

// GLOBALES

/* Variables para fade-in-out de texto */

// Fade-in en forma de lista (Con delays)

.fade-list-item {
    margin: 0;
    opacity: 0;
    animation-name: fadein;
    animation-duration: 2000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Fade-in por secciones

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.body{
    background-color: rgba(41, 41, 41);
}

// CUSTOM LOADING COMPONENT EXE

.image-container {
    position: relative;
    top: 0;
    left: 0;
    width: 300px;
    height: 102px;
    filter: brightness(75%);
    -webkit-filter: brightness(75%);
    background-image: url('./../assets/img/logo-exe-blanco.png');
    background-size: cover;
    mask-image: url('./../assets/img/logo-exe-blanco.png');
    mask-size: cover;
}

.image-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    mask-image: url('./../assets/img/logo-exe-blanco.png');
    mask-size: cover;
    clip-path: circle(0% at 0% 100%);
    animation: fillColor 2.5s infinite linear;
}

@keyframes fillColor {
    0% {
        clip-path: circle(0% at 0% 100%);
    }
    
    100% {
        clip-path: circle(150% at 0% 100%);
    }
}

.container-boton{
    display: flex;
    display: -webkit-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.boton-general-azul{
    background-color : #00A5A4;
}

.boton-general-verde{
    background-color : #72BF44;
}

.boton-garantias{
    background-color : #00A5A4;
    white-space: nowrap;
    margin-bottom: 10px;
}

// CABECERA

.cabecera-tipo{
    font-family: 'LibreFranklin';
    font-size: 1.5rem;
    text-align: center;
    white-space: nowrap;
    font-weight: bolder;
}

.imagen-atras{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    min-height: 100vh;
    height: auto;
    min-width: 100%;
    width: auto;
    opacity: 0.4;
}

.logo-inicio{
    display: block;
    align-self: center;
    min-width: 50%;
    max-width: 30vw;
    width: auto;
    height: auto;
}

.logo-centro{
    display: block;
    align-self: center;
    max-width: calc(150px + 20vw);
    width: auto;
    height: auto;
}

.titulo-inicio{
    font-family: 'LibreFranklin';
    font-size: calc(2.4rem + 0.5vw);
    text-align: center;
    color: #ffffff;
}

.texto-inicio{
    font-family: 'Urbanist';
    font-size: calc(1rem + 0.25vw);
    text-align: center;
    color: #ffffff;
}

.titulo-colaboradores{
    font-family: 'Flama';
    font-size: calc(1rem + 4vw);
    text-align: center;
    color: rgb(38, 174, 238);
}

.texto-colaboradores{
    font-family: 'Enriqueta';
    font-size: calc(1rem + 2vw);
    text-align: center;
    color: #000000;
}

.overf{
    overflow-x: scroll;
    overflow-y: scroll;
}

.svhcontainer{
    height: 80vh;
    overflow: hidden;
}

// Breakpoints de CoreUI para usar en el futuro
// @include media-breakpoint-up(sm) {

// }

// @include media-breakpoint-up(md) {

// }

// @include media-breakpoint-up(lg) {

// }

// @include media-breakpoint-up(xl) {

// }

// @include media-breakpoint-up(xxl) {

// }

.google-btn {
    cursor: pointer;
    margin-top: 5px;
    width: 100%;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    transition: box-shadow .3s ease;
    
    .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #fff;
    }
    
    .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
    }
    
    .btn-text {
        float: left;
        margin: 10px 80px;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.2px;
    }
    
    &:hover {
        box-shadow: 0 0 6px #4285f4;
    }
    
    &:active {
        background: #1669f2;
    }
}

